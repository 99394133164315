"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var Tooltip_jsx_1 = require("orfeo_common/Tooltip.jsx");
var trans = __importStar(require("orfeo/ui/common/static/js/i18next_initializer.jsx"));
var UserProfilePhoto = function (_a) {
    var _b;
    var _c = _a.size, size = _c === void 0 ? '24px' : _c, profile = _a.profile, tooltip = _a.tooltip;
    var tooltipText = tooltip !== undefined
        ? tooltip
        : ((_b = profile === null || profile === void 0 ? void 0 : profile.display_name) !== null && _b !== void 0 ? _b : trans.t('Aucun utilisateur'));
    /**
     * Use user info as seed to create a random color for its avatar
     * The algorithm has magic numbers to get a nice color
     * @returns a random color string in HSL format
     */
    var avatarColor = (0, react_1.useMemo)(function () {
        if (!profile)
            return;
        // A UserProfile object may send its identifier under a pk or id field,
        // this might be fixed in the future
        var seed = "".concat(profile.display_name).concat(profile.pk);
        var hash = 0;
        for (var i = 0; i < seed.length; i++) {
            hash = (hash << 5) - hash + seed.charCodeAt(i);
        }
        hash = Math.abs(hash);
        var normalizeHash = function (min, max) {
            return Math.floor((hash % (max - min)) + min);
        };
        var h = normalizeHash(0, 360);
        var s = normalizeHash(50, 75);
        var l = normalizeHash(25, 60);
        return "hsl(".concat(h, ", ").concat(s, "%, ").concat(l, "%)");
    }, [profile]);
    var blockStyles = {
        '--size': size,
        height: 'var(--size)',
        width: 'var(--size)',
        fontSize: 'calc(0.6 * var(--size))',
    };
    return (react_1.default.createElement(Tooltip_jsx_1.OptionalTooltip, { title: tooltipText },
        react_1.default.createElement("div", { className: "d-inline-block position-relative rounded-circle overflow-hidden", style: blockStyles },
            !profile && (react_1.default.createElement("span", { className: "d-inline-flex align-items-center justify-content-center w-100 h-100 bg-grey" },
                react_1.default.createElement("i", { className: "fa fa-user" }))),
            (profile === null || profile === void 0 ? void 0 : profile.photo) && (react_1.default.createElement("img", { className: "w-100 h-100 object-fit-cover position-absolute top-0 left-0", src: profile.photo, alt: "avatar" })),
            profile && !profile.photo && (react_1.default.createElement("span", { className: "d-inline-flex align-items-center user-select-none justify-content-center w-100 h-100 text-white fw-bold", style: { backgroundColor: avatarColor } }, profile.display_name[0].toUpperCase())))));
};
exports.default = UserProfilePhoto;
